import Header from "../components/Header";
import Footer from "../components/Footer";
import Map from "../components/Map";

const Contact = () => {

    return (
        <>
            <Header />
            <Map />
            <Footer />
        </>
    )
}

export default Contact;