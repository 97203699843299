import Carousel from "./Carousel";

const ExploreComp = () => {

    return(
        <main className="explore-container">
                <h1>Explore Our Services</h1>
                <h6>We are an event planning company that curates thoughtful events, provide great servces with unparelleled attention to details.</h6>
                <Carousel />

                {/* <button type="button" className="btn btn-warning show-more">Show more...</button> */}
        </main>
    )
}

export default ExploreComp;