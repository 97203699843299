import Header from "../components/Header";
import Footer from "../components/Footer";
import CTA from "../components/CTA";

const Landing = () => {
    return (
        <>
            <Header />
            <CTA />
            <Footer />
        </>
        
    )
}

export default Landing;