import Header from "../components/Header";
import Footer from "../components/Footer";
import ExploreComp from "../components/ExploreComp";

const Explore = () => {

    return (
        <>
            <Header />
            <ExploreComp />
            <Footer />
        </>
    )
}

export default Explore;